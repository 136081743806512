body{
  /* margin:0; */
  /* align-items: center; */
   /* padding-top: 10px; */
  }
 .color-1 {
  color: #2198B2;
}

.tile-item h6 {
  font-size: 24px;
  font-weight: 700;
}
.bg-color-1{
  background-color: #2198B2;
}
p{
  font-size: 14px;
}
div{
  font-size: 14px;
}
span{
  font-size: 14px;
}
Form.Select{
  font-size: 14px;
}
.offcanvas{
  width:40% !important;
}  
