.scrollbarstyle::-webkit-scrollbar {
  width: 8px;
}

.scrollbarstyle::-webkit-scrollbar-thumb {
  background-color: #ffffff4e;
  border-radius: 10px;
}

.scrollbarstyle::-webkit-scrollbar-track {
  /* background-color:#8d404a; */
  border-radius: 10px;
}
.scrollbarmainstyle::-webkit-scrollbar {
  width: 8px;
}

.scrollbarmainstyle::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}

.scrollbarmainstyle::-webkit-scrollbar-track {
  /* background-color:#8d404a; */
  border-radius: 10px;
}

.btn_bg{
  background-color: #8d404a;
}
.btn_bg:hover{
  background-color: #7a353e;
}
.text_color{
  background-color: white;
}
.font_size{
  font-size: 12px;
}

.bodyadjust{
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    padding-bottom: 3%;
}


