.rbc-header {
  background-color: #8d404a;
  color: rgb(255, 255, 255); 
  padding-bottom: 20px !important;
  padding-top: 10px  !important;

}
.rbc-today {
  color: #ff0404 !important;
}
 

.rbc-toolbar-label{
  text-transform: capitalize;
  font-weight: bold;
}
.rbc-agenda-empty{
  text-transform: capitalize;
}

.rbc-toolbar-label{
  text-transform: capitalize;
  font-weight: bold;
}
.rbc-agenda-empty{
  text-transform: capitalize;
}
.rbc-agenda-time-cell{
  border-left: 1px solid lightgrey;
}
.rbc-event {
  background-color:#DCF2F1;
  color: #0F1035;
  border: 1px solid #7FC7D9;  
  width: 90%;
  justify-content: center;
}
.rbc-event:hover {
  background-color:#7FC7D9;
  color: #0F1035;
  border: 1px solid #7FC7D9;  
  transition: 1s all;
}
.rbc-agenda-event-cell{
  padding:0px !important;

}
.rbc-event.rbc-selected {
  background-color:#7FC7D9;
  color: #0F1035;
  border: 1px solid#DCF2F1;
}

 
.rbc-show-more{
  color: #9B4444;
}
.rbc-show-more:hover{
  color: #9B4444;
  text-decoration:underline;
}

.rbc-today {
  background-color: #fcf6f6 !important;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 1px auto #DCF2F1;
}

.rbc-month-row, .rbc-event-content {
  overflow: visible;
}
.rbc-row-segment {
  position: relative;
}

.rbc-day-slot .rbc-event{ 
  border: 1px solid #7FC7D9;
}
 
.rbc-today{
   color: white; 
  padding-bottom: 30px;
}


.template{
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  
  
}
.one{
  width: calc(50% - 15px);
    float: left; 
    margin-right: 15px; 
    box-sizing: border-box; 
    position: relative;
    left:8%;
    bottom: 4%;
   
}

.lable{
  flex: 0 0 calc(33.333333% - 15px); 
  max-width: calc(33.333333% - 15px);
  margin-right: 50px; 
  position: relative;
  left:45px;
  bottom: 1px;
  
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: lowercase;
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  background-color: #8D404A;
 color: white; 
}
 
abbr{
  font-size: 12px;
}