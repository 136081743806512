
  .wrapper {
    width: 100%;
    max-width: 31.25rem;
  }
  
  .label {
    font-size: .625rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .searchBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  #searchQueryInput {
    width: 100%;
    height: 2.2rem;
    background: #f5f5f5;
    outline: none;
    border: none;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 14px;
  }
  
  #searchQuerySubmit {
    width: 3.5rem;
    height: 2.8rem;
    margin-left: -3.5rem;
    background: none;
    border: none;
    outline: none;
  }
  
  .custom-toggle::after {
    content: '\25BC';
    color: white;
    margin-left: 5px;
    display: none;
    border-color: #f5f5f500;
  }
  .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: rgba(255, 0, 0, 0);
}
  
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: rgba(255, 0, 0, 0);
}