.tile_item {    
    display: flex;
    padding: 25px 20px;
    border-radius: 15px;
    margin: 0 10px 16px 0;
    min-height: 90%;

}

.grey_bg {
    background-color: #ffffff;

}

.icon_bg {
    border-radius: 100%;
    padding: 6px;
}

.border_2 {
    border: 6px solid #2198B2 !important;
    border-radius: 100% !important;
    padding: 7px !important;
}