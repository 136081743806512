/* src/components/HalfPageImage.css */
.half-page-image-container {
    display: flex;
    /* align-items: center; */
    height: 90vh; 
    overflow: hidden;
  }
  
  .half-page-image {
    width: 100%;
    height: auto;
    align-items: center;
  }

  .overlay {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .content{
    position: relative;
    width: 100%;
    height: 600px;
  }

  .logo-image{
    width: 100px;
  }
.short-about{
    color: white;
    width: 650px;
    text-align: center;
}
 
.quotes {
    display: flex;
    justify-content: center;
  }

  .footer-color{
    color:#662730
  }